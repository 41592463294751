<template>
  <div id="metaboliteidentification">
    <MyheadCompnent></MyheadCompnent>
    <MetaboliteIdentificationComponent></MetaboliteIdentificationComponent>
    <MyfooterCompnent></MyfooterCompnent>
  </div>
</template>

<script>
  import MetaboliteIdentificationComponent from '@/components/Browse/MetaboliteIdentificationComponent.vue'
  import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
  import MyfooterCompnent from '@/components/Common/MyfooterCompnent.vue'
  export default {
    name: 'MetaboliteIdentification',
    data() {
      return {

      }
    },
    components: {
      MetaboliteIdentificationComponent,
      MyheadCompnent,
      MyfooterCompnent,
    },
  }
</script>

<style scoped lang="scss">
</style>