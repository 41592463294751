<template>
  <div>
    <div id="metaboliteidentificationcomponent">
      <MetaboliteBrowseCompnentSearch :headT='head'></MetaboliteBrowseCompnentSearch>
      <el-row>
        <el-col :span="1">
          <div class="grid-content bg-purple"></div>
        </el-col>
        <el-col :span="22">
          <div>
            <h2>Individual</h2>
            <el-row>
              <el-col :span="5">
                <h3
                  style="text-align:center;line-height:50px; background-color: #b3c0d1; margin: 0px 0px 0px 0px; height: 50px;">
                  Q1
                </h3>
                <el-input v-model="q1_individual" placeholder="Please input Q1." :clearable="true" />
                <h3
                  style="text-align:center;line-height:50px; background-color: #b3c0d1; margin: 0px 0px 0px 0px; height: 50px;">
                  MS2
                </h3>
                <el-input v-model="msText" :rows="10" type="textarea"
                  placeholder="Please input ms data. Fragement and Intensity need to be separated with a space or a tab."
                  :clearable="true" />
                <el-collapse>
                  <el-collapse-item title="Parameters" name="1">
                    <!-- <template #title>
                      <h3>Parameters</h3>
                    </template> -->
                    <div class="margin">
                      <h3>ESI mode</h3>
                      <el-select v-model="mode_individual" class="m-2" placeholder="Select">
                        <el-option key="pos" label="pos" value="P"></el-option>
                        <el-option key="neg" label="neg" value="N"></el-option>
                      </el-select>
                    </div>
                    <div class="margin">
                      <h3>Q1 threshold (ppm)</h3>
                      <el-input-number v-model="thresholdQ1_individual" :min="1" :max="100" />
                    </div>
                    <div class="margin">
                      <h3>Qn threshold (ppm)</h3>
                      <el-input-number v-model="thresholdQn_individual" :min="1" :max="100" />
                    </div>
                  </el-collapse-item>
                </el-collapse>
                <!-- <div style="margin: 10px 5px 10px 0px; display: inline-block">
                  <el-button type="primary" @click="plotms">Plot
                    <Histogram style="width:12; height:12" />
                  </el-button>
                </div> -->
                <div style="margin: 10px 5px 10px 0px; display: inline-block">
                  <el-button type="primary" @click="damoData">Demo
                    <Document style="width:12; height:12" />
                  </el-button>
                  <el-button type="primary" :loading="searchLoading" @click="search">Search
                    <Search style="width:12; height:12" />
                  </el-button>
                </div>
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="8">
                <div v-show="msText">
                  <h3
                    style="text-align:center;line-height:50px; background-color: #b3c0d1; margin: 0px 0px 0px 0px; height: 50px;">
                    MS/MS plot
                  </h3>
                  <div v-show="msText" id="msplot" style="border: 1px solid #b3c0d1;"></div>
                </div>
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="9" v-show="individualCandidateList.length>0">
                <h3
                  style="text-align:center;line-height:50px; background-color: #b3c0d1; margin: 0px 0px 0px 0px; height: 50px;">
                  Results
                </h3>
                <el-table :data="individualCandidateList" style="width: 100%" @row-click="plotalign" max-height="300">
                  <el-table-column label="PMhub ID" width="130">
                    <template #default="scope">
                      <!-- <p>{{ scope.row.featureID }}</p> -->
                      <a :href="$store.state.frontbaseURL+'metabolitedetail/'+scope.row.metabolite">{{
                        scope.row.metabolite
                        }}</a>
                    </template>
                  </el-table-column>
                  <el-table-column prop="molecular" label="molecular"></el-table-column>
                  <el-table-column prop="name" label="name"></el-table-column>
                  <el-table-column label="Ms ID" width="200">
                    <template #default="scope">
                      <router-link
                        :to="{name:'MsDetail',params:{metabolite:scope.row.metabolite,msID:scope.row.feature}}">
                        {{ scope.row.feature }}
                      </router-link>
                    </template>
                  </el-table-column>
                  <el-table-column prop="dotproduct" label="dotproduct"></el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </div>
          <div>
            <h2>Batch</h2>
            <el-row>
              <el-col :span="5">
                <el-upload ref="upload" class="upload-demo" drag :headers="headers" :data="batchData"
                  :action="$store.state.backendbaseURL+'search/feature/batch/'" multiple :auto-upload="false"
                  :before-upload="beforeUpload" :on-success="onSuccess" :on-error="onError">
                  <el-icon class="el-icon--upload" :size="120" color="#409EFC">
                    <upload-filled />
                  </el-icon>
                  <div class="el-upload__text">
                    Drop file here or <em>click to upload</em><br />
                    'mgf' file with a size less than 30 Mb<br />
                    'txt' file with a size less than 10 Mb
                  </div>
                </el-upload>
                <el-collapse>
                  <el-collapse-item title="Parameters" name="1">
                    <div class="margin">
                      <h3>File type</h3>
                      <div>
                        <el-select v-model="batchData.fileType" class="m-2" placeholder="Select">
                          <el-option key="mgf" label="mgf" value="mgf"></el-option>
                          <el-option key="txt" label="txt" value="txt"></el-option>
                        </el-select>
                        <el-link v-if="batchData.fileType==='txt'" download='damo.txt'
                          :href="this.$store.state.demoURL + 'pos.txt'" target="_blank">Demo txt
                          file
                          <Download style="width:12; height:12" />
                        </el-link>
                        <el-link v-if="batchData.fileType==='mgf'" download='damo.mgf'
                          :href="this.$store.state.demoURL + 'pos.mgf'" target="_blank">Demo mgf
                          file
                          <Download style="width:12; height:12" />
                        </el-link>
                      </div>
                    </div>
                    <div class="margin">
                      <h3>ESI mode</h3>
                      <el-select v-model="batchData.mode" class="m-2" placeholder="Select">
                        <el-option key="pos" label="pos" value="pos"></el-option>
                        <el-option key="neg" label="neg" value="neg"></el-option>
                      </el-select>
                    </div>
                    <div class="margin">
                      <h3>Q1 threshold (ppm)</h3>
                      <el-input-number v-model="batchData.ppmQ1" :min="1" :max="100" />
                    </div>
                    <div class="margin">
                      <h3>Qn threshold (ppm)</h3>
                      <el-input-number v-model="batchData.ppmQn" :min="1" :max="100" />
                    </div>
                    <div class="margin">
                      <h3>Deduplicate</h3>
                      <el-select v-model="batchData.duplicate" class="m-2" placeholder="Select">
                        <el-option key="1" label="Yes" value="1"></el-option>
                        <el-option key="0" label="No" value="0"></el-option>
                      </el-select>
                    </div>
                  </el-collapse-item>
                </el-collapse>
                <div style="margin: 10px 5px 10px 0px; display: inline-block">
                  <el-button type="primary" :loading="submitLoading" @click="submitUpload">Search
                    <Search style="width:12; height:12" />
                  </el-button>
                </div>
              </el-col>
            </el-row>

          </div>

        </el-col>
      </el-row>

    </div>
  </div>
</template>
<!--  prop="metabolite" -->
<script>
  import request from '@/network/request'
  import $ from 'jquery'
  import ChemDoodle from "../../mymodules/ChemDoodleWeb-9.2.0/install/ChemDoodleWeb.js"
  import MetaboliteBrowseCompnentSearch from "@/components/Browse/MetaboliteBrowseCompnentSearch.vue"
  import { ref } from 'vue'
  import common from '@/mymethods/common.js'
  import { Edit, Upload, Picture, Search, UploadFilled, Histogram, Download, Document } from '@element-plus/icons-vue'
  import * as d3 from 'd3'
  import { ElMessage, ElLoading } from 'element-plus'
  let canvas
  let context1
  export default {
    name: 'MetaboliteIdentificationComponent',
    data () {
      return {
        msText: '',
        headers: {
          Authorization: "Bearer " + this.$store.state.access,
        },
        step: 0,
        q1_individual: '',
        ms2_individual: [],
        thresholdQ1_individual: 10,
        thresholdQn_individual: 10,
        mode_individual: "P",
        individualCandidateList: [],
        individualCandidateMsDict: {},
        loading: false,
        searchLoading: false,
        batchData: {
          mode: "pos",
          ppmQ1: 10,
          ppmQn: 10,
          timeNow: new Date(),
          duplicate: "1",
          fileType: "mgf",
        },
        submitLoading: false,
        head: "Feature Search",
        loadingTotal: "",
      }
    },
    methods: {
      test () {
        this.count += 1
      },
      beforeUpload (file) {
        this.loadingTotal = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
        if (this.batchData.fileType === 'mgf') {
          var fileSizeThreshold = 30
        } else {
          var fileSizeThreshold = 10
        }
        var isLt2M = file.size / 1024 / 1024 < fileSizeThreshold
        if (!isLt2M) {
          var messageT = 'File size of [' + file.name + '] exceeds ' + fileSizeThreshold + ' MB '
          ElMessage({
            message: messageT,
            type: 'error',
          })
        }
        return isLt2M
      },
      onSuccess (res) {
        ElMessage({
          message: 'Congrats, upload successfully. Please wait for the results',
          type: 'success',
          showClose: true,
          duration: 0,
        })
        this.loadingTotal.close()
      },
      onError (res) {
        this.loadingTotal.close()
        ElMessage({
          message: 'You have to login !',
          type: 'error',
        })
      },
      submitUpload () {
        this.$refs.upload.submit()
        // 写view功能
      },
      damoData () {
        this.msText = "166.0499 100\n148.0393 1.63\n167.0528 0.51\n"
        this.q1_individual = "184.0604"
      },
      plotms () {
        var msListTemp = this.msText.split("\n")
        while (msListTemp[msListTemp.length - 1] == "") {
          msListTemp.pop()
        }
        if (this.msText.includes("\t")) {
          var splitStr = "\t"
          // 标记
        } else {
          var splitStr = " "
        }
        this.ms2_individual = []
        for (let i in msListTemp) {
          this.ms2_individual = this.ms2_individual.concat(msListTemp[i].split(splitStr))
        }
        for (let i in this.ms2_individual) {
          this.ms2_individual[i] = Number(this.ms2_individual[i])
        }
        var q1 = Number(this.q1_individual)
        common.drawMsData(this.ms2_individual, "#msplot", q1, 500, 300, 10, 0, "10px", "10px")
      },
      plotalign (row, column, event) {
        var ms2T = this.individualCandidateMsDict[row.feature]
        var ms2 = []
        for (let i in ms2T) {
          if (ms2T[i]) {
            ms2.push(ms2T[i])
          }
        }
        var q1Library = Number(ms2[2])
        ms2 = ms2.splice(4)
        var q1 = Number(this.q1_individual)
        common.drawMsAlign(this.ms2_individual, ms2, "#msplot", q1, q1Library, 500, 300, 10, 0, "10px", "10px")
      },
      search () {
        this.plotms()
        var seachParams = { q1: this.q1_individual, ms2_individual: this.ms2_individual.join("\t"), thresholdQ1: this.thresholdQ1_individual, thresholdQn: this.thresholdQn_individual, mode: this.mode_individual }
        this.searchLoading = true
        // 写view功能
        if (!seachParams.q1 == "" && seachParams.ms2_individual.length > 0) {
          request({
            url: 'search/feature/individual/',
            params: seachParams,
          }).then(res => {

            let resultTemp = res.metaboliteList.sort((a, b) => b.dotproduct - a.dotproduct)
            this.individualCandidateList = resultTemp
            this.individualCandidateMsDict = common.list2dict2(res.ms, "msID")
            // this.individualCandidateMsDict = res.ms
            this.searchLoading = false
          }).catch(err => {
            console.log(err);
            this.searchLoading = false
          });
        } else {

          this.searchLoading = false
        }
      },
      demo () {

      }
    },
    components: {
      MetaboliteBrowseCompnentSearch,
      Edit,
      Upload,
      Picture,
      Search,
      UploadFilled,
      Histogram,
      Download,
      Document,
    },
    created () {

    },
    mounted () {
      window.vue = this
      var that = this
      this.timer = setInterval(function () {
        var d = new Date()
        that.batchData.timeNow = String(d.getFullYear()) +
          ("0" + String(d.getMonth() + 1)).slice(-2) +
          ("0" + String(d.getDate())).slice(-2) +
          ("0" + String(d.getHours())).slice(-2) +
          ("0" + String(d.getMinutes())).slice(-2) +
          ("0" + String(d.getSeconds())).slice(-2)
      }, 1000)
    },
    updated () {

    },
    beforeDestroy () {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    // 检测querydata的编号
    watch: {
    }
  }
</script>

<style scoped lang="scss">
  #locsearchcomponent {
    min-height: 800px
  }

  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
  }

  .el-select .el-input {
    width: 180px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .el-table {
    cursor: pointer;
  }



  .el-input {
    margin: 1px 0px 3px 0px;
  }

  h3 {
    height: 25px;
    margin: 30px 0px 20px 0px;
  }

  .margin {
    margin: 10px 5px 10px 0px
  }
</style>